@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-sider {
  height: 100vh !important;
  background: #fff !important;
}

.ant-layout-header span svg {
  height: 25px !important;
  width: 25px !important;
  margin: 10px !important;
}

.ant-menu-overflow {
  /* justify-content: space-between !important; */
  justify-content: center;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.logo {
  text-align: center !important;
}
.ant-layout-content {
  margin: 0 !important;
  padding: 10px !important;
}

.sweet-loading {
  position: fixed !important;
  top: 30% !important;
  height: 100% !important;
  width: 100% !important;
  z-index: 9999;
}

.flex {
  display: flex !important;
  align-items: center !important;
}
p {
  font-weight: 500 !important;
  margin: 2px !important;
  padding: 2px !important;
  font-size: 16px !important;
}
.bs {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.site-div {
  border-radius: 15px !important;
}

button {
  background-color: #001529 !important;
  color: #fff !important;
}

.login {
  padding-top: 150px !important;
}

input,
textarea {
  border: 1px solid #001529 !important;
  box-shadow: none !important;
}

.ant-message {
  z-index: 9999 !important;
}

.ant-btn {
  height: 32px !important;
}

.ant-input-search {
  width: inherit !important;
}

.ant-image-img {
  height: 40px !important;
  margin-bottom: -12px !important;
}

.searchIcon {
  margin-top: 17px !important;
}

.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
  min-width: 150px;
}

.ant-picker-input > input {
  border: 0 !important;
}

.ant-input {
  border: 1px solid #d9d9d9 !important;
}

.ant-picker-header button {
  color: rgba(0, 0, 0, 0.25) !important;
  background: transparent !important;
}

.ant-switch {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-switch-checked {
  background-color: #3cab0f !important;
}

.ant-btn-dangerous {
  color: #ff4d4f !important;
  border-color: #ff4d4f;
  background: #fff !important;
}

.ant-modal-close {
  color: rgba(0, 0, 0, 0.45) !important;
  background: transparent !important;
}

.deleteBtn {
  background-color: #ff4d4f !important;
}

.deleteBtn:active,
.deleteBtn:focus {
  border-color: #ff4d4f !important;
}

.alendar-strip-wrapper {
  align-items: center;
}

.calendar-strip-action {
  padding: 10px;
}

.calendar-strip-date {
  height: 55px;
  width: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.calendar-strip-date.current p {
  color: #1890ff;
}

.calendar-strip-date:hover,
.calendar-strip-date.active {
  background-color: black;
}

.calendar-strip-date:hover p,
.calendar-strip-date.active p {
  color: white;
}

.calendar-strip-date p {
  font-size: 14px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.calendar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.calendar-strip-action {
  cursor: pointer;
}

.ant-btn-primary {
  border-color: #060606 !important;
  background: #000000 !important;
}

.ant-btn:active {
  border-color: #060606 !important;
}

.logo {
  float: left;
  margin: -6px 20px 0 -36px;
}

.ant-layout-header {
  color: hsla(0, 0%, 100%, 0.85) !important;
  background: #fff !important;
}

.companyName {
  color: black;
  font-family: monospace;
  font-weight: bold;
  border: 1px solid black;
  padding: 0px 10px;
  font-size: 18px;
}

a.addBtn {
  background-color: black;
  padding: 10px;
  color: white;
  cursor: pointer;
}

a.addBtn {
  background-color: black;
  padding: 10px;
  color: white;
  cursor: pointer;
  margin-bottom: 10px;
}

a.addBtn:hover {
  color: white;
}

.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ant-table-thead > tr > th {
  background: #d0ecff !important;
}
